import React, {Component} from 'react'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
import { StoryPageLayout } from '../../components/layouts'
import { withPreview } from '../../components/common'

class StoryPagePreview extends Component {
    constructor(props) {
        super(props)

        this.state = {
            story: null,
            sitemap: []
        }
    }

    componentDidMount() {
        sessionStorage.setItem('preview', true)
        this.props.getSitemap().then(sitemap => {
            this.setState({
                sitemap
            })
        })
        this.props.getEntry('pageStory', this.props.location.search).then(entry => {
            this.initializeStoryPage(entry)
        })
    }

    componentWillUnmount() {
        sessionStorage.setItem('preview', false)
    } 

    initializeStoryPage(entry) {
        const story = {
            __typename: 'ContentfulPageStory',
            ...entry.fields
        }

        story.heroImage = story.heroImage.fields

        story.storyBlock = {
            ...story.storyBlock.fields,
            backgroundImage: story.storyBlock.fields.backgroundImage.fields,
            story: story.storyBlock.fields.story.fields
        }

        this.setState({
            story
        })
    }

    render() {
        const { story, sitemap } = this.state
        const site = this.props.getSiteInfo()

        return (!this.props.loading && story && site ? <StoryPageLayout preview={true} story={story} site={site} sitemap={sitemap} /> : <></>)
    }
}

export default withPreview(StoryPagePreview)